/** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { motion } from 'framer-motion'

interface Props {
  isOpen: boolean
  onClick: () => void
}

export const Overlay: FC<Props> = ({ isOpen, onClick, children, ...props }) => {
  return (
    <motion.div
      css={{
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        display: 'none',
        overflow: 'hidden',
        zIndex: 1000,
      }}
      animate={isOpen ? 'open' : 'close'}
      variants={{
        open: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'block',
        },
        close: {
          backgroundColor: 'rgba(0,0,0,0)',
          transitionEnd: {
            display: 'none',
          },
        },
      }}
      {...props}
    >
      <div
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
        css={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%' }}
      />
      {children}
    </motion.div>
  )
}
