import { combineReducers } from 'redux'
import { FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS } from '../actions'

const lotPerformance = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS:
      newState = { ...state }
      newState[action.lotId] = action.performance
      return newState
    default:
      return state
  }
}

const lotPerformanceByQuot = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS:
      return {
        ...state,
        [action.lotId]: {
          ...state[action.lotId],
          [action.cotId]: action.performance,
        },
      }
    default:
      return state
  }
}

export const getLotPerformance = (state, lotId) =>
  lotId ? state.performance.lotPerformance[lotId] : []

export default combineReducers({
  lotPerformance,
  lotPerformanceByQuot,
})
