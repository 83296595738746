import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import SidebarNav from './sidebarSelection/SidebarNav';
import SidebarTabs from './sidebarSelection/SidebarTabs'
import { fetchContracts, fetchCountry } from '../../actions/contractSelection'

class SideBarSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'SELECTION',
    }
  }

  componentDidMount() {
    this.props.fetchContracts()
    this.props.fetchCountry()
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleSearchPanel = () => {
    this.props.setSearchPanelOpen(!this.props.searchPanelOpen)
  }

  render() {
    return (
      <div>
        {/* <SidebarNav activeTab={this.state.activeTab} toggleTab={this.toggleTab}/> */}
        <SidebarTabs
          activeTab={this.state.activeTab}
          toggleSearchPanel={this.toggleSearchPanel}
          searchPanelOpen={this.props.searchPanelOpen}
        />
      </div>
    )
  }
}

SideBarSelection.propTypes = {
  setSearchPanelOpen: PropTypes.func.isRequired,
  searchPanelOpen: PropTypes.bool.isRequired,
  fetchContracts: PropTypes.func.isRequired,
  fetchCountry: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  fetchContracts: () => {
    dispatch(fetchContracts())
  },
  fetchCountry: () => {
    dispatch(fetchCountry())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarSelection)
