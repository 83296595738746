import React from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { I18nextProvider } from 'react-i18next'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@emotion/react'
import { QueryClient, QueryClientProvider } from 'react-query'

// Config
import {
  i18n,
  // init,
  userManager,
} from 'commons'
//import * as env from './helpers/env'

import configureStore from './configureStore'
import Root from './components/route/Root'
import startAnalytics from './analytics'

// Bundle de traduction
import fr from './locales/fr.json'
import en from './locales/en.json'
import { theme } from './theme'

i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)

// Init before configure store
// init(env)

// Configure store
export const store = configureStore()

const history = createBrowserHistory()
startAnalytics(history)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      suspense: false,
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
    mutations: {
      retry: 0,
      useErrorBoundary: false,
    },
  },
})

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <Router history={history}>
                <Route path="/" component={Root} />
              </Router>
            </QueryClientProvider>
          </ThemeProvider>
        </OidcProvider>
      </Provider>
    </I18nextProvider>
  )
}

export default App
