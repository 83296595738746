import React from 'react'
import { Container, Button } from 'reactstrap'

import SearchFilters from './SearchFilters'

const SearchPanel = ({ searchPanelOpen, setSearchPanelOpen }) => {
  const toggle = () => setSearchPanelOpen(!searchPanelOpen)

  return (
    <div>
      <Container className={'search-panel' + (searchPanelOpen ? ' open' : '')} fluid>
        <Button close onClick={toggle} />

        {searchPanelOpen && (
          <div className="mt-4">
            <SearchFilters />
          </div>
        )}
      </Container>

      <div className="search-panel-overlay" onClick={toggle} />
    </div>
  )
}

export default SearchPanel
