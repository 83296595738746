import { combineReducers } from 'redux'

import {
  FETCH_HEDGING_PLAN_SUCCESS,
  SET_HEDGING_PLAN_LENGTH_SUCCESS,
  SET_HEDGING_PLAN_SEGMENT_SUCCESS,
  UPDATE_HEDGING_PLAN_SEGMENT_REQUEST,
  FETCH_HEDGING_PLAN_RECAP_SUCCESS,
  FETCH_HEDGING_PLAN_RECAP_FAILURE,
  ADD_HEDGING_PLAN_SUCCESS,
  DELETE_HEDGING_PLAN_SEGMENT_SUCCESS,
  DELETE_HEDGING_PLAN_REQUEST,
} from '../actions'

const segments = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_HEDGING_PLAN_SUCCESS:
      newState = { ...state }
      action.plans.forEach((plan) => {
        newState[plan.id] = plan.segments
      })
      return newState
    case ADD_HEDGING_PLAN_SUCCESS:
    case SET_HEDGING_PLAN_LENGTH_SUCCESS:
      newState = { ...state }
      newState[action.plan.id] = action.plan.segments
      return newState
    case SET_HEDGING_PLAN_SEGMENT_SUCCESS:
      newState = { ...state }
      newState[action.planId] = [...newState[action.planId], action.segment]
      return newState
    case DELETE_HEDGING_PLAN_SEGMENT_SUCCESS:
      newState = { ...state }
      newState[action.planId] = [
        ...newState[action.planId].filter((b) => {
          return b.id !== action.segmentId
        }),
      ]
      return newState
    case UPDATE_HEDGING_PLAN_SEGMENT_REQUEST:
      newState = { ...state }
      newState[action.planId] = [
        ...newState[action.planId].filter((b) => {
          return b.id !== action.segment.id
        }),
        action.segment,
      ]
      return newState
    default:
      return state
  }
}

const plan = (state = {}, action) => {
  let newState, index
  switch (action.type) {
    case FETCH_HEDGING_PLAN_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.plans.map((plan) => ({
        id: plan.id,
        strategieId: plan.strategieId,
        duree: plan.duree,
      }))
      return newState
    case ADD_HEDGING_PLAN_SUCCESS:
      newState = { ...state }
      if (newState[action.parcelId]) {
        newState[action.parcelId] = [...newState[action.parcelId], action.plan]
      } else {
        newState[action.parcelId] = [action.plan]
      }
      return newState
    case SET_HEDGING_PLAN_LENGTH_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = [...newState[action.parcelId]]
      index = newState[action.parcelId].findIndex((plan) => plan.id === action.plan.id)
      newState[action.parcelId][index] = action.plan
      // newState[action.parcelId] = [ ...newState[action.parcelId].filter(plan => plan.id !== action.plan.id), action.plan]
      return newState
    case DELETE_HEDGING_PLAN_REQUEST:
      newState = { ...state }
      newState[action.parcelId] = newState[action.parcelId].filter(
        (plan) => plan.id !== action.planId,
      )
      return newState
    default:
      return state
  }
}

const currentSelectionPlanRecap = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_HEDGING_PLAN_RECAP_SUCCESS:
      newState = { ...state }

      if (newState[action.idLot]) {
        newState[action.idLot][action.year] = action.planRecap
      } else {
        newState[action.idLot] = {
          [action.year]: action.planRecap,
        }
      }

      //      newState = action.planRecap;
      return newState
    case FETCH_HEDGING_PLAN_RECAP_FAILURE:
      return null
    default:
      return state
  }
}

export default combineReducers({
  plan,
  segments,
  currentSelectionPlanRecap,
})

export const getHedgingPlans = (state, parcelId) => {
  return state.hedgingPlan.plan[parcelId]
}
export const getHedgingPlanSegments = (state, planId) => {
  return state.hedgingPlan.segments[planId] || []
}
