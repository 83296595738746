import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './locales/en.json'
import fr from './locales/fr.json'

export const lngIso = (lanId: number): string => {
  switch (lanId) {
    case 2:
      return 'en'
    case 1:
    default:
      return 'fr'
  }
}

i18n
  .use(LanguageDetector as any)
  .use(initReactI18next as any)
  .init({
    fallbackLng: 'fr',
    debug: false,
    ns: 'translation',
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false,
    },

    resources: {
      en,
      fr,
    },

    react: {
      wait: true,
      nsMode: 'default',
    },
  })

export default i18n
