import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  FETCH_CONTRACTS_SUCCESS,
  ADD_CONTRACTS_TO_SELECTION,
  REMOVE_CONTRACTS_TO_SELECTION,
  SET_CONTRACT_VISIBLE,
  SET_CONTRACT_INVISIBLE,
  SET_UNIQUE_CONTRACT_VISIBLE,
  SET_CONTRACTS_VISIBLE,
  SET_CONTRACTS_INVISIBLE,
  FETCH_CONTRACT_COVERAGE_SUCCESS,
  FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS,
  FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST,
  FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS,
  FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE,
  FETCH_MONTHLY_PRICES_SUCCESS,
  FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST,
  FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS,
  FETCH_CONTRACTS_DETAIL_SUCCESS,
  FETCH_CONTRACT_CONFIG_STATUS_SUCCESS,
  FETCH_CONTRACT_CONFIG_STATUS_FAILURE,
  FETCH_CONTRACT_CONFIG_STATUS_REQUEST,
  EMPTY_SELECTION,
  FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS,
  FETCH_STRATEGY_FOLLOWUP_SUCCESS,
} from '../actions'

// contract objects
const contractList = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONTRACTS_SUCCESS:
      return action.contracts
    default:
      return state
  }
}

// contract objects
const configurationStatus = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_CONFIG_STATUS_FAILURE:
      return state
    case FETCH_CONTRACT_CONFIG_STATUS_SUCCESS:
      return { ...state, ...action.status }
    case FETCH_CONTRACT_CONFIG_STATUS_REQUEST:
    default:
      return state
  }
}

// list of ids of selected contracts
const contractSelection = (state = [], action) => {
  switch (action.type) {
    case ADD_CONTRACTS_TO_SELECTION:
      return [...new Set(state.concat(action.contractIds))]
    case REMOVE_CONTRACTS_TO_SELECTION:
      return state.filter((ctrId) => !action.contractIds.includes(ctrId))
    case EMPTY_SELECTION:
      return []
    default:
      return state
  }
}

// list of id of visible contracts
const visibleContracts = (state = [], action) => {
  let fltr
  switch (action.type) {
    case ADD_CONTRACTS_TO_SELECTION:
      return [...new Set(state.concat(action.contractIds))]
    case REMOVE_CONTRACTS_TO_SELECTION:
      fltr = state.filter((ctrId) => !action.contractIds.includes(ctrId))
      return fltr
    case SET_CONTRACT_VISIBLE:
      return [...state, action.contractId]
    case SET_CONTRACTS_VISIBLE:
      return [...state, ...action.contractsId]
    case SET_CONTRACT_INVISIBLE:
      fltr = state.filter((ctrId) => ctrId !== action.contractId)
      return fltr
    case SET_CONTRACTS_INVISIBLE:
      fltr = state.filter(
        (ctrId) => !action.contractsId.find((actionCtrIds) => actionCtrIds === ctrId),
      )
      return fltr
    case EMPTY_SELECTION:
      return []
    default:
      return state
  }
}

// Zoom sur un contrat (state = objet contract)
const zoomOnContract = (state = null, action) => {
  switch (action.type) {
    case SET_UNIQUE_CONTRACT_VISIBLE:
      return action.contract
    case REMOVE_CONTRACTS_TO_SELECTION:
      if (state) return action.contractIds.find((ctrId) => ctrId === state.id) ? null : state
      return state
    default:
      return state
  }
}

const currentSelectionContractCoverage = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_CONTRACT_COVERAGE_SUCCESS:
      newState = action.contractCoverage
      return newState
    default:
      return state
  }
}

const currentSelectionContractCoverageRecap = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS:
      newState = action.contractCoverageRecap
      return newState
    default:
      return state
  }
}

// For the currently visible contracts
const currentSelectionMonthlyContractCoverage = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS:
      newState = action.monthlyContractCoverage
      return newState
    default:
      return state
  }
}

const currentSelectionMonthlyContractCoverageError = (state = false, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST:
    case FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS:
      return null
    case FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE:
      return action.message
    default:
      return state
  }
}

// For the currently visible contracts
const currentSelectionMonthlyPrices = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_MONTHLY_PRICES_SUCCESS:
      newState = action.monthlyPrices
      return newState
    default:
      return state
  }
}

const currentSelectionMonthlyContractCoveragePerCountry = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST:
      return null
    case FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS:
      return action.coveragePerCountry
    default:
      return state
  }
}

const contractDetails = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONTRACTS_DETAIL_SUCCESS:
      return action.details
    default:
      return state
  }
}

const strategyFollowup = (state = [], action) => {
  switch (action.type) {
    case FETCH_STRATEGY_FOLLOWUP_SUCCESS:
      return action.strategyFollowup
    default:
      return state
  }
}

const monthlyConverageFollowup = (state = [], action) => {
  switch (action.type) {
    case FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS:
      return action.monthlyConverageFollowup
    default:
      return state
  }
}

const contractUuid = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CONTRACT_REQUEST':
      return {
        ...state,
        [action.ctrid]: action.uuid,
      }
    default:
      return state
  }
}

const contractsDetails = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CONTRACT_SUCCESS':
      return {
        ...state,
        ...action.response.entities.contracts,
      }
    default:
      return state
  }
}

const contractsDetailsLoading = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CONTRACT_REQUEST':
      return true
    case 'FETCH_CONTRACT_SUCCESS':
    case 'FETCH_CONTRACT_FAILURE':
      return false
    default:
      return state
  }
}

const contractsDetailsError = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_CONTRACT_REQUEST':
      return null
    case 'FETCH_CONTRACT_FAILURE':
      return action.message
    default:
      return state
  }
}

export default combineReducers({
  contractList,
  configurationStatus,
  contractSelection,
  visibleContracts,
  zoomOnContract,
  contractDetails,
  currentSelectionContractCoverage,
  currentSelectionContractCoverageRecap,
  currentSelectionMonthlyContractCoverage,
  currentSelectionMonthlyContractCoverageError,
  currentSelectionMonthlyPrices,
  currentSelectionMonthlyContractCoveragePerCountry,
  monthlyConverageFollowup,
  strategyFollowup,
  contractUuid,
  contractsDetails,
  contractsDetailsLoading,
  contractsDetailsError,
})

// SELECTORS
export const getContracts = (state) => state.contracts.contractList
export const getContract = (state, contractId) =>
  state.contracts?.contractList.find((cl) => cl.id === contractId)

export const getZoomOnContract = (state) => state.contracts.zoomOnContract

export const getSelectedContract = (state) => {
  return state.contracts.zoomOnContract
    ? [state.contracts.zoomOnContract]
    : state.contracts.visibleContracts.length === 1
    ? [getContract(state, state.contracts.visibleContracts[0])]
    : getVisibleContractsObjets(state)
}

//export const getSelectedContract = state => state.contracts.zoomOnContract ? state.contracts.zoomOnContract : state.contracts.visibleContracts.length === 1 ? getContract(state, state.contracts.visibleContracts[0]) : null;

export const getStrategyFollowup = (state) => state.contracts.strategyFollowup
export const getMonthlyConverageFollowup = (state) => state.contracts.monthlyConverageFollowup
export const getVisibleContracts = (state) => state.contracts.visibleContracts
export const getContractList = (state) => state.contracts.contractList
export const getAllContractDetails = (state) => state.contracts.contractDetails

export const getVisibleContractsObjets = createSelector(
  getVisibleContracts,
  getContractList,
  (visibleContracts, contractList) => {
    return visibleContracts.reduce((acc, vc) => {
      const c = contractList.find((cl) => cl.id === vc)
      if (c) acc.push(c)
      return acc
    }, [])
  },
)

export const getVisibleContractDetailObjects = createSelector(
  getVisibleContracts,
  getAllContractDetails,
  (visibleContracts, contractDetails) =>
    visibleContracts.map((vc) => contractDetails.find((cl) => cl.id === vc)).filter((vc) => !!vc),
)

export const getContractsListUuid = (state) => state.contracts.contractsListUuid
export const getContractUuid = (state, ctrid) => state.contracts.contractUuid[ctrid]
export const getMetersListUuid = (state) => state.contracts.metersListUuid
export const getMeterUuid = (state, peeid) => state.contracts.meterUuid[peeid]
export const getContractDetails = (id) => (state) => state.contracts.contractsDetails[id] || {}
export const getContractDetailsError = (state) => state.contracts.contractsDetailsError
export const getContractDetailsLoading = (state) => state.contracts.contractsDetailsLoading
export const getContractSelection = (state) => state.contracts.contractSelection

export const getContractsSelectOptions = (state) =>
  getContractSelection(state).map((contractId) => ({
    value: contractId,
    label: getContract(state, contractId).CtrNomCourt,
  }))
