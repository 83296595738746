import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const AccessDenied: FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <p>{t('access.denied')}</p>
      <p>
        <a href="https://portal.eleneo.fr/">{t('access.backToPortal')}</a>
      </p>
    </div>
  )
}
