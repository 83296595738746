import { combineReducers } from 'redux'

import {
  FETCH_PARCEL_EDGES_REQUEST,
  FETCH_PARCEL_EDGES_FAILURE,
  FETCH_PARCEL_EDGES_SUCCESS,
  ADD_PARCEL_EDGES_REQUEST,
  ADD_PARCEL_EDGES_FAILURE,
  ADD_PARCEL_EDGES_SUCCESS,
  UPDATE_PARCEL_EDGES_REQUEST,
  UPDATE_PARCEL_EDGES_FAILURE,
  UPDATE_PARCEL_EDGES_SUCCESS,
  DELETE_PARCEL_EDGES_REQUEST,
} from '../actions'

const parcelHedges = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_PARCEL_EDGES_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.blocks
      return newState
    case ADD_PARCEL_EDGES_SUCCESS:
      newState = { ...state }
      if (!newState[action.parcelId]) newState[action.parcelId] = []
      newState[action.parcelId] = newState[action.parcelId].concat(action.block)
      return newState
    case UPDATE_PARCEL_EDGES_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = [
        ...newState[action.parcelId].filter((b) => {
          return b.id !== action.block.id
        }),
        action.block,
      ]
      return newState
    case DELETE_PARCEL_EDGES_REQUEST:
      return {
        ...state,
        [action.parcelId]: state[action.parcelId].filter((b) => b.id !== action.block.id),
      }
    default:
      return state
  }
}

const parcelHedgesLoading = (state = false, action) => {
  switch (action.type) {
    case FETCH_PARCEL_EDGES_REQUEST:
    case ADD_PARCEL_EDGES_REQUEST:
    case UPDATE_PARCEL_EDGES_REQUEST:
      return true
    case FETCH_PARCEL_EDGES_SUCCESS:
    case ADD_PARCEL_EDGES_SUCCESS:
    case UPDATE_PARCEL_EDGES_SUCCESS:
    case FETCH_PARCEL_EDGES_FAILURE:
    case ADD_PARCEL_EDGES_FAILURE:
    case UPDATE_PARCEL_EDGES_FAILURE:
      return false
    default:
      return state
  }
}

const profile = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_PARCEL_EDGES_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.profile
      return newState
    default:
      return state
  }
}

export default combineReducers({
  profile,
  parcelHedges,
  parcelHedgesLoading,
})

export const getParcelHedgesLoading = (state) => state.hedgingProfile.parcelHedgesLoading
