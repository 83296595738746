import { setToken, setPlatform } from '../helpers/apiConf'
import get from 'lodash/get'

// import { setReady } from 'store/api'

let isInit = false
const accessTokenMiddleware = (store: any) => (next: any) => (action: any) => {
  if (isInit) return next(action)

  const state = store.getState()
  const token = get(state, 'oidc.user.access_token')
  const platform = get(state, 'oidc.user.profile.pla')

  if (token && platform) {
    isInit = true
    setToken(token)
    setPlatform(platform)
    // store.dispatch(setReady())
  }

  return next(action)
}

export default accessTokenMiddleware
