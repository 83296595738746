interface State {
  user: {
    userInfo: {
      IsAdmin: boolean
      CodePays: number
      UsrLogin: string
    }
    userPostError: string
    userPostSuccess: boolean
    passwordError: string
    passwordSuccess: boolean
  }
}

export const postSuccess = (state: State) => state.user && state.user.userPostSuccess
export const postError = (state: State) => state.user && state.user.userPostError
export const passwordSuccess = (state: State) => state.user && state.user.passwordSuccess
export const passwordError = (state: State) => state.user && state.user.passwordError
export const userDetail = (state: State) => state.user && state.user.userInfo

export const isAdmin = (state: State) =>
  (state.user && state.user.userInfo && state.user.userInfo.IsAdmin) || false

// Si le code pays utilisateur n'est pas renseigné, on indique France (1)
export const paysId = (state: State) =>
  (state.user && state.user.userInfo && state.user.userInfo.CodePays) || 1
