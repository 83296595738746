import { getUor } from '../reducers'

export const customerTheme = (state) => {
  switch (getUor(state)) {
    case '735':
      return 'vinci'
    case '620':
      return 'stef'
    default:
      return ''
  }
}
