import { SET_SELECTED_YEAR } from '../actions'

const execution = (state = { selectedYear: null }, action) => {
  switch (action.type) {
    case SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.year,
      }
    default:
      return state
  }
}

export const getSelectedYear = (state) => state.execution.selectedYear

export default execution
