import { createSelector } from 'reselect'
import { superFetch } from 'commons'

import { getContractList, getAllContractDetails } from 'reducers/contracts'

// API
export const fetchHedgingReport = ({ contractIds }) => {
  return superFetch({
    url: 'contrats/hedgingreport/parametres/load',
    method: 'POST',
    body: contractIds,
  })
}

export const saveHedgingReport = ({ payload }) => {
  return superFetch({
    url: 'contrats/hedgingreport/parametres/save',
    method: 'POST',
    body: payload,
  })
}

// Action types
const SET_START_YEAR = 'eflex/reporting/SET_START_YEAR'
const SET_END_YEAR = 'eflex/reporting/SET_END_YEAR'
const SET_SELECTION = 'eflex/reporting/SET_SELECTION'
const SET_BYPARCEL = 'eflex/reporting/SET_BYPARCEL'
const TOGGLE_BYPARCEL = 'eflex/reporting/TOGGLE_BYPARCEL'

// Action creators
export const setStartYear = (date) => ({ type: SET_START_YEAR, date })
export const setEndYear = (date) => ({ type: SET_END_YEAR, date })
export const setSelection = (ids) => ({ type: SET_SELECTION, ids })
export const setByParcel = (ids) => ({ type: SET_BYPARCEL, ids })
export const toggleByParcel = (id) => ({ type: TOGGLE_BYPARCEL, id })

// Reducers
const reporting = (
  state = {
    startYear: new Date().getFullYear(),
    endYear: null,
    selection: [],
    byParcel: [],
  },
  action,
) => {
  switch (action.type) {
    case SET_START_YEAR:
      return {
        ...state,
        startYear: action.date,
      }
    case SET_END_YEAR:
      return {
        ...state,
        endYear: action.date,
      }
    case SET_SELECTION:
      return {
        ...state,
        selection: action.ids,
      }
    case SET_BYPARCEL:
      return {
        ...state,
        byParcel: action.ids,
      }
    case TOGGLE_BYPARCEL: {
      const byParcel = state.byParcel || []
      const index = byParcel.findIndex((id) => id === action.id)
      return {
        ...state,
        byParcel:
          index === -1 ? [...byParcel, action.id] : byParcel.filter((id) => id !== action.id),
      }
    }
    default:
      return state
  }
}

export default reporting

// Selectors
export const getStartYear = (state) => state.reporting.startYear
export const getEndYear = (state) => state.reporting.endYear
export const getSelection = (state) => state.reporting.selection || []
export const getByParcel = (state) => state.reporting.byParcel || []

export const getFilteredSelection = createSelector(
  getStartYear,
  getEndYear,
  getSelection,
  getContractList,
  (startYear, endYear, selection, contracts) => {
    return selection.filter((id) => {
      const contract = contracts.find((c) => c.id === id)
      return (
        contract &&
        (!endYear || contract.CtrDateDebut.split('/')[2] <= endYear) &&
        (!startYear || contract.CtrDateFin.split('/')[2] >= startYear)
      )
    })
  },
)

export const getContractListObjects = createSelector(
  getSelection,
  getContractList,
  (selection, contractList) =>
    selection.reduce((acc, id) => {
      const c = contractList.find((c) => c.id === id)
      if (c) acc.push(c)
      return acc
    }, []),
)

export const getContractDetailObjects = createSelector(
  getSelection,
  getAllContractDetails,
  (selection, contractDetails) =>
    selection.reduce((acc, id) => {
      const c = contractDetails.find((c) => c.id === id)
      if (c) acc.push(c)
      return acc
    }, []),
)
