import * as env from 'helpers/env'
import axios, { Canceler } from 'axios'

type Token = null | string

let token: Token = null
export const setToken: (t: Token) => void = (newToken) => (token = newToken)

let userPlatform = 'MUT'
export const setPlatform: (s: string) => void = (newPlatform) => (userPlatform = newPlatform)

type Result<T> = Promise<T> & { cancel: Canceler }

/**
 * Wrapper d'appel HTTP pour gérer le cas de la déconnexion en 401 lors de l'expiration du cookie de refresh.
 * Le content-type est 'application/json'
 * Pour l'envoie de cookie le header 'credentials' est à 'include',
 *
 * @param {string} url Chemin relatif de l'API. Cf. <code>apiUrl</code> - Mandatory
 * @param {string} method Chemin relatif de l'API. Cf. <code>apiUrl</code> - Optional - Default 'GET'
 * @param {object} body Objet à serialiser dans le body - Optional - Default null
 * @param {string} uuid ID unique de la request - Optional - Default null
 * @param {string} contentType content type de la request - Optional - Default 'application/json'
 */
export function superFetch<T>({
  url,
  method = 'GET',
  body = null,
  uuid = null,
  contentType = 'application/json',
  platform,
  returnType = 'data',
}: {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  body?: unknown
  uuid?: string | null
  contentType?: string | null
  platform?: string
  returnType?: 'data' | 'response'
}): Result<T> {
  if (!url) throw new Error('Url est obligatoire pour le fetch wrapper')

  const source = axios.CancelToken.source()

  if (!platform) {
    platform = userPlatform
  }

  const baseUrl = env.apiUrl(platform)

  const headers = {
    ...(contentType && { 'Content-Type': contentType }),
    ...(uuid && { 'X-REQUEST-ID': uuid }),
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  let request = null

  // console.debug('superFetch : url: ' + url + ' method: ' + method + ' body: ', body)

  switch (method) {
    case 'POST':
    case 'PATCH':
    case 'PUT':
      request = axios.request<T>({
        url: `${baseUrl}${url}`,
        withCredentials: true,
        method,
        data: body,
        headers,
        cancelToken: source.token,
      })
      break
    default:
      request = axios.request<T>({
        url: `${baseUrl}${url}`,
        withCredentials: true,
        method,
        headers,
        cancelToken: source.token,
      })
  }

  const query = (returnType === 'response'
    ? request
    : request.then(({ data }) => data)) as Result<T>

  query.cancel = () => source.cancel('Query was cancelled')

  return query
}
