/** @jsxImportSource @emotion/react */
import { VFC } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { apiUrl } from 'helpers/env'
import { version } from '../../../package.json'

export const Version: VFC = () => {
  const { data } = useQuery<any>('version', () =>
    axios(`${apiUrl()}version`).then((res) => res.data),
  )

  return (
    <div
      css={(theme: any) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        background: theme.gray100,
        padding: '0.4rem 1.2rem',
        margin: '0 -15px',
      })}
    >
      {`${data?.version ?? '\u2013'} / v${version}`}
    </div>
  )
}
