/** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { Button } from 'reactstrap'
import { motion } from 'framer-motion'

import { Overlay } from './Overlay'

interface Props {
  isOpen: boolean
  close: () => void
  width?: number
  position?: 'left' | 'right'
}

export const SlidePanel: FC<Props> = ({
  isOpen,
  close,
  width = 400,
  position = 'left',
  children,
  ...props
}) => {
  const isRight = position === 'right'

  return (
    <Overlay isOpen={isOpen} onClick={close} {...props}>
      <motion.div
        css={(theme: any) => ({
          position: 'absolute',
          top: 0,
          backgroundColor: theme.gray200,
          height: '100%',
          overflowY: 'auto',
          width: width,
          padding: '1rem',
          left: isRight ? '100vw' : 'unset',
          right: isRight ? 'unset' : '100vw',
        })}
        animate={isOpen ? 'open' : 'close'}
        variants={{
          open: {
            translateX: width * (isRight ? -1 : 1),
            display: 'block',
          },
          close: {
            translateX: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{
          duration: 0.4,
          ease: 'anticipate',
        }}
      >
        <Button close onClick={close} css={{ position: 'relative', zIndex: 1000 }} />
        {children}
      </motion.div>
    </Overlay>
  )
}
