/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Button, Alert } from 'reactstrap'

export const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation()
  return (
    <Alert color="danger" css={{ padding: '1.25rem 1.25rem', maxWidth: 500 }}>
      <p css={{ marginBottom: '1rem' }}>{t('global.error')}</p>
      <Button color="secondary" size="sm" onClick={resetErrorBoundary}>
        {t('global.tryAgain')}
      </Button>
    </Alert>
  )
}

export const ErrorBoundary: React.FC = ({ children }) => {
  return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>
}
