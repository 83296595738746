import { FETCH_FILTERS_PARAM_SUCCESS } from '../actions'

const providers = (state = [], action) => {
  switch (action.type) {
    case FETCH_FILTERS_PARAM_SUCCESS:
      return action.providers
    default:
      return state
  }
}

export default providers

export const getProviders = (state) => state.providers
