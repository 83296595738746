import set from 'lodash/fp/set'

type GlobalState = {
  market: MarketState
}

export type MarketState = {
  selectedQuotation: object[]
  dateMin: string
  dateMax: string
}

type MarketAction =
  | { type: 'setSelectedQuotation'; payload: object[] }
  | { type: 'setDateMin'; payload: string }
  | { type: 'setDateMax'; payload: string }

const initialState: MarketState = {
  selectedQuotation: [],
  dateMin: '',
  dateMax: '',
}

const marketReducer = (state: MarketState = initialState, action: MarketAction) => {
  switch (action.type) {
    case 'setSelectedQuotation':
      return set('selectedQuotation', action.payload, state)
    case 'setDateMin':
      return set('dateMin', action.payload, state)
    case 'setDateMax':
      return set('dateMax', action.payload, state)
    default:
      return state
  }
}

export default marketReducer

// Actions

export const setMarketSelectedQuotation = (payload: object[]): MarketAction => ({
  type: 'setSelectedQuotation',
  payload,
})

export const setMarketDateMin = (payload: string): MarketAction => ({ type: 'setDateMin', payload })
export const setMarketDateMax = (payload: string): MarketAction => ({ type: 'setDateMax', payload })

// Selectors

export const getMarketSelectedQuotation = (state: GlobalState) =>
  state.market?.selectedQuotation ?? []

export const getMarketDateMin = (state: GlobalState) => state.market?.dateMin ?? ''
export const getMarketDateMax = (state: GlobalState) => state.market?.dateMax ?? ''
