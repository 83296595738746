import { store } from 'react-notifications-component'

export const addNotification = ({
  type = 'default',
  title,
  message,
}: {
  type?: 'success' | 'warning' | 'danger' | 'info' | 'default'
  title?: string
  message: string
}) => {
  store.addNotification({
    insert: 'top',
    container: 'bottom-left',
    animationOut: ['fadeOut'],
    dismiss: {
      duration: 3000,
      showIcon: true,
    },
    type,
    title,
    message,
  })
}
