import { FETCH_FILTERS_PARAM_SUCCESS, FETCH_COUNTRIES_SUCCESS } from '../actions'

const countries = (state = [], action) => {
  switch (action.type) {
    case FETCH_FILTERS_PARAM_SUCCESS:
    case FETCH_COUNTRIES_SUCCESS:
      return action.countries
    default:
      return state
  }
}

export default countries

export const getCountriesByCode = (state) => {
  const res = {}
  state.countries.forEach((x) => {
    res[x['code']] = x
  })
  return res
}

export const getCountriesById = (state) => {
  const res = {}
  state.countries.forEach((x) => {
    res[x['id']] = x
  })
  return res
}

export const getCountries = (state) => state.countries
