import moment from 'moment'
import englishIndicator from 'ordinal/indicator'

import { store } from '../App'
import * as user from 'commons'

export const indicator = (value, lang) =>
  lang === 'fr' ? (value > 1 ? 'ème' : 'er') : englishIndicator(value)

// A bit hacky but re-using eview mechanic
export const dateFormat = () => {
  switch (user.lanId(store.getState())) {
    case 2:
      return 'YYYY-MM-DD'
    case 1:
    default:
      return 'DD/MM/YYYY'
  }
}

export const formatDate = (date) => moment(date).format(dateFormat())

export const noDecimalFormat = (x) => {
  let val
  switch (user.lanId(store.getState())) {
    case 2:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
    case 1:
    default:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
  }
}

export const decimalFormat = (x, { digitCount = 2, force = false, useGrouping = true } = {}) => {
  if (!force && Number.isInteger(x)) return noDecimalFormat(x)
  //let val;
  switch (user.lanId(store.getState())) {
    //anglais
    case 2:
      return new Intl.NumberFormat('en-EN', {
        maximumFractionDigits: digitCount,
        minimumFractionDigits: digitCount,
        useGrouping,
      }).format(x)
    //val = parseFloat(x).toFixed(2, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
    case 1:
    //francais
    // eslint-disable-next-line no-fallthrough
    default:
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: digitCount,
        minimumFractionDigits: digitCount,
        useGrouping,
      }).format(x)

    //val = parseFloat(x).toFixed(2, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
  }
}

export const uppercaseFirst = (s) => s.charAt(0).toUpperCase() + s.slice(1)
