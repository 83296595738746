import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import More from 'highcharts/highcharts-more'
import Boost from 'highcharts/modules/boost'
import PatternFill from 'highcharts/modules/pattern-fill'

import {
  highchartsEn,
  rangeSelectorMarketEn,
  rangeSelectorLoadCurveEn,
} from 'locales/highcharts-en'

import {
  highchartsFr,
  rangeSelectorMarketFr,
  rangeSelectorLoadCurveFr,
} from 'locales/highcharts-fr'

// Init modules
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsExporting(Highstock)
HighchartsExportData(Highstock)
More(Highstock)
Boost(Highcharts)
Boost(Highstock)
PatternFill(Highcharts)

// Set options
export const setHighchartsOptions = (lanId) => {
  const options = lanId === 1 ? highchartsFr : highchartsEn
  Highcharts.setOptions(options)
  Highstock.setOptions(options)
}

export const rangeSelectorMarket = (lanId) => {
  switch (lanId) {
    case 2:
      return rangeSelectorMarketEn
    case 1:
    default:
      return rangeSelectorMarketFr
  }
}

export const rangeSelectorLoadCurve = (lanId) => {
  switch (lanId) {
    case 2:
      return rangeSelectorLoadCurveEn
    case 1:
    default:
      return rangeSelectorLoadCurveFr
  }
}

export { Highcharts, Highstock }
