import { superFetch } from 'commons'

export const fetchContractsQuotations = (contractIds) => {
  return superFetch({
    url: 'cotations/search',
    method: 'POST',
    body: contractIds,
  })
}

export const fetchParcelQuotations = (parcelId, debut, fin) => {
  let url = `lots/${parcelId}/cotation`
  if (debut && fin) {
    const strDebut = debut.format('YYYY-MM-DD')
    const strFin = fin.format('YYYY-MM-DD')
    url = `${url}?debut=${strDebut}&fin=${strFin}`
  }
  return superFetch({ url: url })
}

export const fetchLotQuotationsForDeals = (parcelId, debut, fin) => {
  let url = `lots/${parcelId}/cotation/eligibledeal`
  if (debut && fin) {
    const strDebut = debut.format('YYYY-MM-DD')
    const strFin = fin.format('YYYY-MM-DD')
    url = `${url}?debut=${strDebut}&fin=${strFin}`
  }
  return superFetch({ url: url })
}

export const fetchAvailableQuotations = (parcelId) => {
  return superFetch({ url: `lots/${parcelId}/cotation/market` })
}

export const addQuotations = (parcelId, quotations) => {
  return superFetch({
    url: `lots/${parcelId}/cotation`,
    method: 'POST',
    body: quotations,
  })
}

export const deleteQuotation = (parcelId, quotationId) => {
  return superFetch({
    url: `lots/${parcelId}/cotation/${quotationId}`,
    method: 'DELETE',
  })
}

export const fetchLotQuotationGraph = (
  lotId,
  quotationId,
  showAlertes,
  showTransactions,
  showEvt = false,
) => {
  let pShowAlertes = showAlertes ? 'true' : 'false'
  let pshowTransactions = showTransactions ? 'true' : 'false'
  return superFetch({
    url: `lots/${lotId}/cotation/${quotationId}/graph?showAlertes=${pShowAlertes}&showTransactions=${pshowTransactions}&showEvt=${showEvt}`,
  })
}

///////////////////////////////////////////////////////////////////////////////
// Cotations Suivies par un utilisateur
///////////////////////////////////////////////////////////////////////////////

/**
 * Récupère les cotations disponibles pour le user connecté
 */
export const fetchMyAvailableQuotations = () => {
  return superFetch({ url: `cotations/me/market` })
}

/**
 * Récupère les cotations suivies par l'utilisateur connecté
 */
export const fetchMyQuotations = () => {
  return superFetch({ url: 'cotations/me' })
}

/**
 * Ajoute des cotations suivies pour l'utilisateur connecté
 */
export const addQuotationForUser = (quotations) => {
  return superFetch({
    url: 'cotations/me',
    method: 'POST',
    body: quotations,
  })
}

/**
 * Supprime une cotation suivie par l'utilisateur connecté
 */
export const deleteMyQuotation = (idCotation) => {
  return superFetch({
    url: `cotations/me/${idCotation}`,
    method: 'DELETE',
  })
}

/**
 * Récupère le graph de cotation d'une quotation
 *
 * @param {*} quotationId
 */
export const fetchQuotationGraph = (quotations, { step = 4 } = {}) => {
  return superFetch({
    url: `cotations/graphv2?pas=${step}`,
    method: 'POST',
    body: quotations,
  })
}

export const fetchParcelAndQuotations = (contractIds) => {
  return superFetch({
    url: 'cotations/search',
    method: 'POST',
    body: contractIds,
  })
}

export const fetchParcelAndQuotationsByPeriod = (contractIds, startDate, endDate) => {
  const query = {
    debut: typeof startDate === 'string' ? startDate : startDate.format('DD/MM/YYYY'),
    fin: typeof endDate === 'string' ? endDate : endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }

  return superFetch({
    url: 'cotations/search/period',
    method: 'POST',
    body: query,
  })
}

/**
 * Récupère les favoris disponibles de la DMR pour le client connecté
 */
export const fetchAvailableDmrQuotations = () => {
  return superFetch({
    url: 'cotations/dmr/market',
  })
}

/**
 * Récupère les favoris de la DMR pour le client connecté
 */
export const fetchDmrQuotations = () => {
  return superFetch({
    url: 'cotations/dmr',
  })
}

/**
 * Ajoute des cotations annexes ppour la Daily Market Report de l'utilisateur connecté
 */
export const addDmrQuotations = (quotationIds) => {
  return superFetch({
    url: 'cotations/dmr',
    method: 'POST',
    body: quotationIds,
  })
}

/**
 * Active ou désactive une cotation de la DMR pour l'utilisateur connecté
 */
export const toggleDmrQuotation = (quotationId) => {
  return superFetch({
    url: `cotations/dmr/${quotationId}`,
    method: 'POST',
  })
}

/**
 * Supprime une cotation annexe de la DMR pour l'utilisateur connecté
 */
export const deleteDmrQuotation = (quotationId) => {
  return superFetch({
    url: `cotations/dmr/${quotationId}`,
    method: 'DELETE',
  })
}
