/** @jsxImportSource @emotion/react */
import React from 'react'
import { Spinner } from 'reactstrap'

const Container = ({ children }) => (
  <div
    css={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.5)',
    }}
  >
    {children}
  </div>
)

export const TableSpinner = ({ loading = true } = {}) => {
  if (!loading) return null

  return (
    <Container>
      <Spinner color="primary" />
    </Container>
  )
}

export const ChartSpinner = () => {
  return (
    <Container>
      <Spinner color="primary" />
    </Container>
  )
}
