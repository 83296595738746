import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import SidebarSelection from './SidebarSelection'
import { getUor } from 'reducers'
import logoVinci from 'images/logo_vinci.svg'
import logoStef from 'images/logo_stef.png'
import { DefaultLogo } from './DefaultLogo'

const Sidebar = ({ setSearchPanelOpen, searchPanelOpen }) => {
  const { t } = useTranslation()
  const uor = useSelector(getUor)

  return (
    <Row>
      <Col className="sidebar">
        <Row>
          <Col className="px-0">
            {uor === '735' ? (
              <Link to="/" className="logo">
                <img src={logoVinci} alt={t('sidebar.logoAlt')} className="img-fluid" />
              </Link>
            ) : uor === '620' ? (
              <Link to="/" className="logo">
                <img src={logoStef} alt={t('sidebar.logoAlt')} className="img-fluid" />
              </Link>
            ) : (
              <DefaultLogo />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SidebarSelection
              setSearchPanelOpen={setSearchPanelOpen}
              searchPanelOpen={searchPanelOpen}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Sidebar
