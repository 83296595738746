import * as env from 'helpers/env'
import { superFetch } from '../../helpers/apiConf'

export const logout: (t: string) => void = (accessToken) => {
  sessionStorage.clear()
  localStorage.clear()
  const redirectLogoutUrl = encodeURIComponent(env.postLogoutRedirectUrl as string)
  // const redirectLogoutUrl = encodeURIComponent(env.autorityUrl as string)
  const logoutUrl = `${env.autorityUrl}/connect/endsession?id_token_hint=${accessToken}&post_logout_redirect_uri=${redirectLogoutUrl}`
  // On vide le session storage pour ne plus avoir d'information sur le user connecté
  document.location.href = logoutUrl
}

export const userInfos: (p: string) => Promise<any> = (platform) =>
  superFetch({
    url: `utilisateur`,
    platform,
  })

export const postUser: (u: any, p: string) => Promise<any> = (user, platform) =>
  superFetch({
    url: `saveutilisateur?id=${user.id}`,
    method: 'PUT',
    body: user,
    platform,
  })

export const changePassword: (pc: any, p: string) => Promise<any> = (passwordChange, platform) =>
  superFetch({
    url: 'changepassword',
    method: 'POST',
    body: passwordChange,
    platform,
  })
