import set from 'lodash/fp/set'
import compact from 'lodash/compact'

type GlobalState = {
  perf: PerfState
}

type PerfState = {
  contractId: number | null
  year: number | null
  strategies: Strategies
  valeur: Valeur
}

type Strategies = {
  SPOT: boolean
  'M+1': boolean
  'Q+1': boolean
  'Y+1': boolean
}

export type Strategy = keyof Strategies
export type Valeur = 'Min' | 'Moy' | 'Max' | 'Decile3' | 'Decile7'

type PerfAction =
  | { type: 'setContractId'; payload: number | null }
  | { type: 'setYear'; payload: number | null }
  | { type: 'toggleStrategy'; payload: Strategy }
  | { type: 'setValeur'; payload: Valeur }

const initialState: PerfState = {
  contractId: null,
  year: null,
  strategies: {
    SPOT: true,
    'M+1': false,
    'Q+1': false,
    'Y+1': false,
  },
  valeur: 'Moy',
}

const perfReducer = (state: PerfState = initialState, action: PerfAction) => {
  switch (action.type) {
    case 'setContractId':
      return set('contractId', action.payload, state)
    case 'setYear':
      return set('year', action.payload, state)
    case 'toggleStrategy': {
      const prevValue = state.strategies?.[action.payload] ?? false
      const isLast = prevValue === true && compact(Object.values(state.strategies)).length === 1
      return isLast ? state : set(`strategies.${action.payload}`, !prevValue, state)
    }
    case 'setValeur':
      return set('valeur', action.payload, state)
    default:
      return state
  }
}

export default perfReducer

// Actions

export const setPerfContractId = (payload: number | null): PerfAction => ({
  type: 'setContractId',
  payload,
})
export const setPerfYear = (payload: number | null): PerfAction => ({ type: 'setYear', payload })
export const togglePerfStrategy = (payload: Strategy): PerfAction => ({
  type: 'toggleStrategy',
  payload,
})
export const setPerfValeur = (payload: Valeur): PerfAction => ({ type: 'setValeur', payload })

// Selectors

export const getPerfContractId = (state: GlobalState) => state.perf?.contractId ?? null
export const getPerfYear = (state: GlobalState) => state.perf?.year ?? null
export const getPerfStrategies = (state: GlobalState) => state.perf?.strategies ?? {}
export const getPerfValeur = (state: GlobalState) => state.perf?.valeur ?? null

export const getPerfStrategyIds = (state: GlobalState) =>
  Object.entries(getPerfStrategies(state))?.reduce<string[]>((acc, [s, b]) => {
    if (b) acc.push(s)
    return acc
  }, [])
