import 'react-app-polyfill/ie11'
import smoothscroll from 'smoothscroll-polyfill'

import 'typeface-muli'

import './assets/css/index.scss'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { unregister } from './registerServiceWorker'
import initMoment from './helpers/moment'

smoothscroll.polyfill()
initMoment()

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootElement,
// )

// ReactDOM.createRoot(rootElement).render(<App />)

ReactDOM.render(<App />, document.getElementById('root'))

unregister()
